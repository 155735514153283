<template>
    <div id="sensor-detail">
        <el-card style="width:92%;min-height:500px;margin:20px 4%;" v-loading="loading"
            element-loading-text="正在与传感器建立连接" element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.8)">
            <p style="width:100%;height:7%;margin-top:1%;font-size:16px;">
                <span style="margin-left:20px;" v-if="searchByLogo == ''">当前工作网关数：{{ landData.length }}（截止 <b>{{ nowTime
                        }}</b>）</span>
                <span style="margin-left:20px;" v-else>已筛选网关数：{{ landData.filter(data => !searchByLogo ||
                    data.gatewayLogo.toLowerCase().includes(searchByLogo.toLowerCase())).length }}</span>
                <el-input style="width:25%;float:right;margin:-8px 20px 12px 0px;" v-model="searchByLogo"
                    placeholder="请输入网关号筛选" />
            </p>
            <el-table
                :data="landData.filter(data => !searchByLogo || data.gatewayLogo.toLowerCase().includes(searchByLogo.toLowerCase()))"
                style="width: 100%;height:90%;margin-top:2%;">
                <el-table-column type="expand">
                    <template slot-scope="props">
                        <el-form label-position="left" inline class="demo-table-expand">
                            <div v-for="(item,index) in props.row.channelName.split(',')" :key="index">
                                <el-form-item :label="item" style="text-align: left;">
                                    <span style="line-height: 30px;">{{ props.row.channelValue.split(',')[index]
                                        }}</span>
                                </el-form-item>
                                <el-form-item label="上报时间" style="text-align: left;">
                                    <span style="line-height: 30px;">{{ props.row.dataTime.split(',')[index] }}</span>
                                </el-form-item>
                            </div>
                        </el-form>
                    </template>
                </el-table-column>
                <el-table-column label="网关号" prop="gatewayLogo">
                </el-table-column>
                <el-table-column label="设备地址">
                    水发某地
                </el-table-column>
                <el-table-column label="工作模式">
                    客户端模式
                </el-table-column>
            </el-table>
        </el-card>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                landData: [],
                searchByLogo: "",
                loading: true,
                nowTime: "",
            }
        },
        created() {
            this.currentData();
        },
        methods: {
            currentData() {
                this.$webAxios.post("/jsh-api/census/getLandSensor").then(res => {
                    res = res.data;
                    this.landData = res.lands;
                    this.loading = false;
                    this.nowTime = getCurrentTime();
                })
                    .catch(err => {
                        err;
                    })
            }
        }
    }

    function getCurrentTime() {
        var d = new Date(),
            str = '';
        str += d.getFullYear() + '-';
        str += d.getMonth() + 1 + '-';
        str += d.getDate() + ' ';
        str += d.getHours() + ':';
        str += d.getMinutes() + ':';
        str += d.getSeconds();
        return str;
    }
</script>

<style>
    #sensor-detail .demo-table-expand {
        font-size: 0;
    }

    #sensor-detail .demo-table-expand label {
        width: 200px;
        color: #99a9bf;
    }

    #sensor-detail .demo-table-expand .el-form-item {
        margin-right: 0;
        margin-bottom: 0;
        width: 40%;
        margin-left: 2%;
    }
</style>